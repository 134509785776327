
$font-stack: 'Lora','Helvetica Neue', 'Helvetica', 'Arial', Sans-serif;
$font-size-base: 14px;

@font-face {
  font-family: 'Lora';
  src: url('../fonts/Lora-VariableFont_wght.ttf');
}

body {
  font-family: $font-stack;
  font-size: $font-size-base;

  color: #000;
}

@media screen and (min-width: 768px) {
  body {
    // font-size: 14px;
  }
}

h1, h2, h3, h4, h5, h6{
  // font-weight: 600;
  // font-variation-settings: 'wght' 98;
}

footer {
  font-size: $font-size-base - 2;
}
