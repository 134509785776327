// @import "reset";
@import "typography";
// @import "speeditup";
// @import "stories";

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body{
  color: #000;    
}

a, a:visited, a:hover, a:active{
	color: #404040;
}

h1, h2, h3, h4, h5, h6{
	// font-weight: 500;
}

ul{
	list-style: circle;
}

.clearfix:before,
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1; /* IE < 8 */
}

body > header, body > main, footer {
  padding: 0 25px;
}

header{
  width: 100%;
  overflow: hidden;
}

header a {
  position: relative;
  display: block;
  text-decoration: none;		
}


// main#main {
//   max-width: 768px;
// }

footer{
  position: relative;
  width: 100%;
  margin: 50px auto 0 auto;
  clear: both;    
}

.tiny-aside {	
	text-align: left;
}

/**
Posts
**/
.post-tag {
  font-size: 0.8em;
  margin-right: 5px;
  border: 1px solid #000;
  border-radius: 2px;
  padding: 0 4px;
}

ul.post-list {
  list-style: none;
  padding: 0;

  .post-group-label {
  }

  .post-item {
    margin-bottom: 5px;

    h2 {
      font-size: 1em;
      display: inline;
    }
  }
}

/**
Single Post
**/

article.post {
  .post-content {
    p {
      max-width: 768px;
    }

    figure.image {
      margin: 0 0 20px 0;      
      // max-width: 768px;
      display: inline-block;

      img {
        display: block;
        max-width: 100%;
        max-height: 550px;
        object-fit: contain;
      }

      figcaption {

      }

      @media screen and (min-width: 1024px) {
       
      }
    }
  }
}

.resume-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  * {
    margin: 0;
  }
}

/**
Post Content
**/

section.image-group {
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  
  scroll-snap-type: x mandatory;      
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  padding-left: 25px;
  margin-left: -25px;
  margin-right: -25px;  

  &:-webkit-scrollbar-thumb {
    background: black;
  }

  &:-webkit-scrollbar-thumb {
    background: transparent;
  }

  figure.image {
    display: inline-block;
    max-width: 80vw;
    scroll-snap-align: start;
    
    img {
      object-fit: contain;
      max-width: 100%;
    }
    figcaption {
      display: inline-block;
    }    
  }
}
section.image-group::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
section.image-group::-webkit-scrollbar-thumb {
  background: black;
}

section.image-group::-webkit-scrollbar-track {
  background: transparent;
}